import { createRouter, createWebHashHistory } from 'vue-router'
// import isAuthenticatedGuard from './auth-guard'
import {isAuthenticatedGuard,loginGuard} from './auth-guard'



const Autorizacion = () => import(/* webpackChunkName: "Autorizacion" */ '@/modules/inscripcion/pages/Autorizacion')
const Encuesta = () => import(/* webpackChunkName: "Encuesta" */ '@/modules/inscripcion/pages/Encuesta')
const DatosAcademicos = () => import(/* webpackChunkName: "DatosAcademicos" */ '@/modules/inscripcion/pages/DatosAcademicos')
const DatosApoderado = () => import(/* webpackChunkName: "DatosApoderado" */ '@/modules/inscripcion/pages/DatosApoderado')
const DatosPersonales = () => import(/* webpackChunkName: "DatosPersonales" */ '@/modules/inscripcion/pages/DatosPersonales')
const Documentos = () => import(/* webpackChunkName: "Documentos" */ '@/modules/inscripcion/pages/Documentos')
const ExamenAdmision = () => import(/* webpackChunkName: "ExamenAdmision" */ '@/modules/inscripcion/pages/ExamenAdmision')
const VerNotaExamen = () => import(/* webpackChunkName: "VerNotaExamen" */ '@/modules/inscripcion/pages/VerNotaExamen')
const Prueba = () => import(/* webpackChunkName: "Prueba" */ '@/modules/inscripcion/pages/Prueba')
const NoPageFound = () => import(/* webpackChunkName: "NoPageFound" */ '@/modules/shared/pages/NoPageFound')

const routes = [
    {
        path: '',
        redirect:  '/autenticacion'
    },
    {
        path: '/inscripcion',
        name: 'inscripcion',
        beforeEnter:isAuthenticatedGuard,
        component: () => import(/* webpackChunkName: "InscripcionLayout" */ '@/modules/inscripcion/layouts/InscripcionLayout'),
        children: [
            {
                path: 'autorizacion',
                name: 'inscripcion-autorizacion',
                component: Autorizacion
            },
            {
                path: 'encuesta',
                name: 'inscripcion-encuesta',
                component: Encuesta
            },
            {
                path: 'datospersonales',
                name: 'inscripcion-datospersonales',
                component: DatosPersonales
            },
            {
                path: 'datosapoderado',
                name: 'inscripcion-datosapoderado',
                component: DatosApoderado
            },
            {
                path: 'datosacademicos',
                name: 'inscripcion-datosacademicos',
                component: DatosAcademicos
            },
            {
                path: 'examenadmision',
                name: 'inscripcion-examenadmision',
                component: ExamenAdmision
            },
            {
                path: 'cargadocumentos',
                name: 'inscripcion-cargadocumentos',
                component: Documentos
            },
            {
                path: 'vernotaexamen',
                name: 'inscripcion-vernotaexamen',
                component: VerNotaExamen
            },
            
            {
                path: 'inscripcion-pruebaid/:id',
                name: 'inscripcion-pruebaid',
                component: Prueba,
                props: (route) => {
                    const id = Number(route.params.id);
                    return isNaN(id) ? { id: 1 } : { id }
                }
            },
            {
                path: '/inscripcion',
                // redirect: '/inscripcion/autorizacion'
                redirect: { name: 'inscripcion-autorizacion' }

            },
        ]
    },

    {
        path: '/autenticacion',
        name: 'autenticacion',
        beforeEnter: loginGuard, // Aplicar el guardia loginGuard al login

        component: () => import(/* webpackChunkName: "AutenticacionLayout" */ '@/modules/autenticacion/layouts/AutenticacionLayout'),
        children: [
            {
                path: 'login',
                name: 'autenticacion-login',
                component: () => import(/* webpackChunkName: "login" */ '@/modules/autenticacion/pages/Login')
            },
            {
                path: 'register',
                name: 'autenticacion-register',
                component: () => import(/* webpackChunkName: "register" */ '@/modules/autenticacion/pages/Register')
            },
            {
                path: '/autenticacion',
                redirect: { name: 'autenticacion-login' }

            },
        ]
    },


    {
        path: '/:pathMatch(.*)*',
        component: NoPageFound
    },
]



const router = createRouter({
    history: createWebHashHistory(),
    routes,
})
 


export default router