 

const isAuthenticatedGuard = async (to, from, next) => {
    // console.log({ to, from, next })

     

    return new Promise(() => {

        const idLead = localStorage.getItem('id_lead');

        if (idLead) {
            next();
        } else {
            next({ name: 'autenticacion-login' });
        }

    })

}



const loginGuard = async (to, from, next) => {
    console.log({ to, from, next })

    const idLead = localStorage.getItem('id_lead');

 
    // Si el usuario está autenticado, redirigir a otra página
    if (idLead) {
        next({ name: 'inscripcion-autorizacion' });
    } else {
        // Si el usuario no está autenticado, permitir el acceso normalmente
        next();
    }
};


// export default isAuthenticatedGuard
export { isAuthenticatedGuard, loginGuard };
